const IconArrowCircle = ({
  fill = '#DEA8FF',
  className,
  dataTestid = 'icon-arrow-circle'
}) => (
  <svg
    data-testid={dataTestid}
    className={className}
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 434">
      <circle
        data-testid={`${dataTestid}-circle`}
        id="Ellipse 52"
        cx="15.9993"
        cy="15.0227"
        r="15.0227"
        fill={fill}
      />
      <path
        className={`${className}-path`}
        data-testid={`${dataTestid}-path`}
        id="Vector"
        d="M21.3543 15.7253V15.7182C21.3803 15.6582 21.3951 15.5946 21.3951 15.5239C21.3951 15.4532 21.3803 15.3897 21.3543 15.3296V15.3225C21.3246 15.2554 21.2838 15.1989 21.2319 15.1494L16.0044 10.1715C15.7856 9.96303 15.4331 9.96303 15.2179 10.1715C15.0027 10.3799 14.999 10.7155 15.2179 10.9205L19.4956 14.994H9.93346C9.62552 14.994 9.37695 15.2307 9.37695 15.5239C9.37695 15.8171 9.62552 16.0538 9.93346 16.0538H19.4956L15.2179 20.1274C14.999 20.3358 14.999 20.6714 15.2179 20.8763C15.3255 20.9788 15.4702 21.0318 15.6112 21.0318C15.7522 21.0318 15.8969 20.9788 16.0044 20.8763L21.2319 15.8984C21.2838 15.8489 21.3246 15.7889 21.3543 15.7253Z"
        fill="#001F19"
      />
    </g>
  </svg>
);

export default IconArrowCircle;
